import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useUserPermissions } from '@/app/useUserPermissions';
import { ClientEditForm } from '@/components/ClientModal/ClientEditForm';
import { ClientEditModal } from '@/components/ClientModal/ClientEditModal';
import { ClientOverviewPanelContainer } from '@/components/ClientOverviewPanel/ClientOverviewPanelContainer';
import { Container } from '@/components/Container/Container';
import { FiltersButton } from '@/components/FiltersPanel/FiltersButton';
import { SearchPanel } from '@/components/SearchPanel/SearchPanel';
import { DatePeriodSelectContainer } from '~/src/components/DatePeriodSelect/DatePeriodSelectContainer';
import { FiltersPanel } from '~/src/components/FiltersPanel/FiltersPanel';
import { FormOrderWithDetailsModal } from '~/src/forms/FormOrderWithDetails/FormOrderWithDetailsModal';
import { FormPurchaseWithDetailsModal } from '~/src/forms/FormPurchaseWithDetails/FormPurchaseWithDetailsModal';
import { useClientModal } from '../Clients/useClientModal';
import { useFiltersState } from '../Clients/useFiltersState';
import { ROUTES } from '../routes';
import { EventsPanel } from './EventsPanel';
import styles from './styles.module.scss';
import { useData } from './useData';
import { useEventsFilters } from './useEventsFilters';
export const handleEntityLink = (entityType, entityId, mailingType) => {
    if (!entityId ||
        (entityType === 'mailing' && !mailingType && mailingType !== 0))
        return;
    let url = '';
    if (mailingType === 0)
        url = `${ROUTES.MAILINGS_AUTOMATIC_CREATE}/${entityId}`;
    if (mailingType === 1)
        url = `${ROUTES.MAILINGS_MANUAL_CREATE}/${entityId}`;
    if (entityType === 'offer')
        url = `${ROUTES.OFFERS_CREATE}/${entityId}`;
    if (entityType === 'promocode')
        url = `${ROUTES.PROMOCODES_CREATE}/${entityId}`;
    if (entityType === 'segment')
        url = ROUTES.CLIENTS;
    return url;
};
export const Events = () => {
    const userHasAccessTo = useUserPermissions();
    const { t } = useTranslation();
    const { user, currency } = useCurrentUser();
    const { client: selectedClient, openOverview, isClientModalOpen, closeClientModal, clientModalActiveTab, changeModalActiveTab, } = useClientModal();
    let isReturnFirst = false;
    const [selectedPurchaseId, setSelectedPurchaseId] = useState(undefined);
    const [selectedOrderId, setSelectedOrderId] = useState(undefined);
    const handlePurchase = (purchseId) => {
        setSelectedPurchaseId(purchseId);
    };
    const handleOrder = (orderId) => {
        setSelectedOrderId(orderId);
    };
    const handleReturn = (purchseId) => {
        isReturnFirst = true;
        setSelectedPurchaseId(purchseId);
    };
    const { segment, filters, compiledFilters, updateFilters, reset } = useFiltersState({ currency, localStorageKey: 'eventsFilters' });
    const { isLoading, isPrevButtonDisabled, isNextButtonDisabled, clientsEventList, activeDataTypesFilters, resetOffset, handleApplySearch, handleChangeFilters, handleNextPage, handlePrevPage, } = useData({
        filters: (filters === null || filters === void 0 ? void 0 : filters.filters.length) ? compiledFilters : undefined,
    });
    const eventsFiltersTree = useEventsFilters();
    const handleUpdateFilters = (filters) => {
        resetOffset();
        updateFilters(filters);
    };
    const userHasEventsView = userHasAccessTo('CLIENTS_PURCHASES_LIST') || (user === null || user === void 0 ? void 0 : user.isUser);
    if (!user)
        return null;
    return userHasEventsView ? (_jsxs(Container, Object.assign({ notMobileRightPadding: true, topElement: _jsx(DatePeriodSelectContainer, { onChange: resetOffset, leftElement: _jsxs("div", Object.assign({ className: styles.topElement }, { children: [_jsx(FiltersButton, { filtersTree: eventsFiltersTree, value: (filters === null || filters === void 0 ? void 0 : filters.filters) || [], onUpdate: handleUpdateFilters }), _jsx(SearchPanel, { className: styles.searchPanel, name: 'searchText', placeholder: '\u041D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430', hasMaxWidth: true, onApplySearch: handleApplySearch })] })), label: t('Показать события за') }) }, { children: [_jsx(FiltersPanel, { filtersTree: eventsFiltersTree, value: (filters === null || filters === void 0 ? void 0 : filters.filters) || [], onUpdate: handleUpdateFilters, hasResetButton: !!(filters === null || filters === void 0 ? void 0 : filters.filters.length), onResetFilters: () => {
                    resetOffset();
                    reset();
                }, segment: segment, isSegmentFiltersTouched: filters === null || filters === void 0 ? void 0 : filters.touched, user: user }), _jsx(EventsPanel, { isLoading: isLoading, eventsData: clientsEventList, activeButtons: activeDataTypesFilters, openOverview: openOverview, handlePurchase: handlePurchase, handleOrder: handleOrder, handleReturn: handleReturn, handleEntityLink: handleEntityLink, handleNextPage: handleNextPage, handlePrevPage: handlePrevPage, isPrevButtonDisabled: isPrevButtonDisabled, isNextButtonDisabled: isNextButtonDisabled, handleChangeFilters: handleChangeFilters }), _jsx(ClientEditModal, { isOpen: isClientModalOpen, onClose: closeClientModal, activeTab: clientModalActiveTab, onChangeTab: changeModalActiveTab, clientOverview: _jsx(ClientOverviewPanelContainer, { client: selectedClient.client, bonusHistory: selectedClient.bonusHistory, clientReservedBonuses: selectedClient.reservedBonuses || 0, user: user, onCorrectBonuses: () => { }, onAddPurchase: () => { } }), clientForm: _jsx(ClientEditForm, { selectedClient: selectedClient.client, onUpdated: closeClientModal, onDeleted: () => { } }) }), _jsx(FormPurchaseWithDetailsModal, { isReturnFirst: isReturnFirst, isShowFooter: false, isOpen: !!selectedPurchaseId, onClose: () => setSelectedPurchaseId(undefined), currency: currency, selectedPurchase: selectedPurchaseId
                    ? { id: selectedPurchaseId }
                    : undefined, purchasesListLength: 0, selectedPurchaseIndex: null, setSelectedPurchaseIndex: () => { } }), _jsx(FormOrderWithDetailsModal, { isOpen: !!selectedOrderId, onClose: () => setSelectedOrderId(undefined), currency: currency, selectedOrder: selectedOrderId ? { id: selectedOrderId } : undefined, ordersListLength: 0, selectedOrderIndex: null, setSelectedOrderIndex: () => { } })] }))) : null;
};
