import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { Promocode } from '@/components/Promocode/Promocode';
import { BonusMovement } from '~/src/icons/BonusMovement';
import styles from '../styles.module.scss';
export const bonusTitleRenderer = ({ event, translate, entityHandlers, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4;
    const { handlePurchase, handleEntityLink, handleReturn, openOverview } = entityHandlers;
    // Бонусов, потрачено на покупку
    if (event.eventType === '0')
        return {
            color: 'red',
            Icon: _jsx(BonusMovement, {}),
            title: (_jsxs(_Fragment, { children: [event.params.amount
                        ? translate('BONUSES_SPENT_ON', { count: event.params.amount })
                        : translate('Бонусы потрачены на'), "\u00A0", _jsx("span", Object.assign({ className: ((_a = event.params) === null || _a === void 0 ? void 0 : _a.purchaseId) ? styles.underline : '', onClick: () => { var _a; return handlePurchase((_a = event.params) === null || _a === void 0 ? void 0 : _a.purchaseId); } }, { children: translate('покупку') }))] })),
        };
    // Бонусов за покупку
    if (event.eventType === '1')
        return {
            color: 'green',
            Icon: _jsx(BonusMovement, { className: styles.rotateIcon, fill: '#499E5C' }),
            title: (_jsxs(_Fragment, { children: [((_b = event.params) === null || _b === void 0 ? void 0 : _b.amount)
                        ? translate('BONUSES_FOR', { count: (_c = event.params) === null || _c === void 0 ? void 0 : _c.amount })
                        : translate('Бонусы за'), "\u00A0", _jsx("span", Object.assign({ className: ((_d = event.params) === null || _d === void 0 ? void 0 : _d.purchaseId) ? styles.underline : '', onClick: () => { var _a; return handlePurchase((_a = event.params) === null || _a === void 0 ? void 0 : _a.purchaseId); } }, { children: translate('покупку') }))] })),
        };
    // Х бонусов просрочено
    if (event.eventType === '2')
        return {
            color: 'gray',
            Icon: _jsx(BonusMovement, { className: styles.rotateIcon, fill: '#828282' }),
            title: ((_e = event.params) === null || _e === void 0 ? void 0 : _e.amount)
                ? translate('LOYALTY_PROGRAM_BONUSES_BURNED_OUT', {
                    count: (_f = event.params) === null || _f === void 0 ? void 0 : _f.amount,
                })
                : translate('Часть бонусов сгорело'),
        };
    // Отмена бонусов начисленных за возвращенные товары
    if (event.eventType === '3')
        return {
            color: 'red',
            Icon: _jsx(BonusMovement, {}),
            title: (_jsxs(_Fragment, { children: [((_g = event.params) === null || _g === void 0 ? void 0 : _g.amount)
                        ? translate('BONUSES_FOR_RETURN_GOODS_FROM', {
                            count: (_h = event.params) === null || _h === void 0 ? void 0 : _h.amount,
                        })
                        : translate('Списаны бонусы за возврат товаров с'), "\u00A0", _jsx("span", Object.assign({ className: ((_j = event.params) === null || _j === void 0 ? void 0 : _j.purchaseId) ? styles.underline : '', onClick: () => { var _a; return handlePurchase((_a = event.params) === null || _a === void 0 ? void 0 : _a.purchaseId); } }, { children: translate('purchases_range_one') }))] })),
        };
    // Начисление акционных бонусов
    if (event.eventType === '4') {
        const link = handleEntityLink('mailing', (_k = event.params) === null || _k === void 0 ? void 0 : _k.mailingBrandId, (_l = event.params) === null || _l === void 0 ? void 0 : _l.mailingBrandType);
        return {
            color: 'green',
            Icon: _jsx(BonusMovement, { className: styles.rotateIcon, fill: '#499E5C' }),
            title: (_jsxs(_Fragment, { children: [((_m = event.params) === null || _m === void 0 ? void 0 : _m.amount)
                        ? translate('BONUSES_FOR_PROMOTION', {
                            count: (_o = event.params) === null || _o === void 0 ? void 0 : _o.amount,
                        })
                        : translate('Начислены бонусы по акции'), "\u00A0", link ? (_jsx(Link, Object.assign({ className: styles.underline, to: link, target: '_blank' }, { children: (_p = event.params) === null || _p === void 0 ? void 0 : _p.mailingBrandName }))) : (((_q = event.params) === null || _q === void 0 ? void 0 : _q.mailingBrandName) && (_jsx("span", { children: `"${event.params.mailingBrandName}"` })))] })),
        };
    }
    // Ручное списание бонусов
    if (event.eventType === '5')
        return {
            tooltipTitle: ((_r = event.params) === null || _r === void 0 ? void 0 : _r.operatorName)
                ? translate('WRITTEN_OFF_BY_EMPLOYEE', {
                    operatorName: event.params.operatorName,
                })
                : undefined,
            color: 'red',
            Icon: _jsx(BonusMovement, {}),
            title: (_jsxs(_Fragment, { children: [event.params.amount
                        ? translate('PUECHASE_BONUSES_FOR_DEDUCTED_MANUALLY', {
                            count: event.params.amount,
                        })
                        : translate('Бонусы за'), "\u00A0", _jsx("span", Object.assign({ className: ((_s = event.params) === null || _s === void 0 ? void 0 : _s.purchaseId) ? styles.underline : '', onClick: () => { var _a; return handlePurchase((_a = event.params) === null || _a === void 0 ? void 0 : _a.purchaseId); } }, { children: translate('покупку') })), "\u00A0", event.params.amount
                        ? translate('списано вручную')
                        : translate('списаны вручную')] })),
        };
    // Бонусов потраченных на возвращенные товары с покупки
    if (event.eventType === '6')
        return {
            tooltipTitle: ((_t = event.params) === null || _t === void 0 ? void 0 : _t.operatorName)
                ? translate('ACCRUED_TO_EMPLOYEES', {
                    operatorName: event.params.operatorName,
                })
                : undefined,
            color: 'green',
            Icon: _jsx(BonusMovement, { className: styles.rotateIcon, fill: '#499E5C' }),
            title: (_jsxs(_Fragment, { children: [((_u = event.params) === null || _u === void 0 ? void 0 : _u.amount)
                        ? translate('RECOVERED_BONUSES_SPENT_ON_PURCHASE_IN_CASE_OF_GOODS_RETURNING', {
                            count: event.params.amount,
                        })
                        : translate('Начисление бонусов с'), "\u00A0", _jsx("span", Object.assign({ className: ((_v = event.params) === null || _v === void 0 ? void 0 : _v.purchaseReturnId) ? styles.underline : '', onClick: () => { var _a; return handleReturn((_a = event.params) === null || _a === void 0 ? void 0 : _a.purchaseReturnId); } }, { children: translate('возврата товаров') }))] })),
        };
    // Отмена бонусов за отмененную покупку / удаленную покупку
    if (event.eventType === '7')
        return {
            tooltipTitle: ((_w = event.params) === null || _w === void 0 ? void 0 : _w.operatorName)
                ? translate('WRITTEN_OFF_BY_EMPLOYEE', {
                    operatorName: event.params.operatorName,
                })
                : undefined,
            color: 'red',
            Icon: _jsx(BonusMovement, { className: styles.rotateIcon }),
            title: (_jsxs(_Fragment, { children: [event.params.amount
                        ? translate('BONUSES_FOR_CANCELATION_OR_DELETION', {
                            count: event.params.amount,
                        })
                        : translate('Списаны бонусы за отмену (или удаление)'), "\u00A0", _jsx("span", Object.assign({ className: ((_x = event.params) === null || _x === void 0 ? void 0 : _x.purchaseId) ? styles.underline : '', onClick: () => { var _a; return handlePurchase((_a = event.params) === null || _a === void 0 ? void 0 : _a.purchaseId); } }, { children: translate('purchase_range_one') }))] })),
        };
    // Возврат Х бонусов списанных на возвращенную покупку
    if (event.eventType === '8')
        return {
            tooltipTitle: ((_y = event.params) === null || _y === void 0 ? void 0 : _y.operatorName)
                ? translate('ACCRUED_TO_EMPLOYEES', {
                    operatorName: event.params.operatorName,
                })
                : undefined,
            color: 'green',
            Icon: _jsx(BonusMovement, { className: styles.rotateIcon, fill: '#499E5C' }),
            title: (_jsxs(_Fragment, { children: [event.params.amount
                        ? translate('RETURN_APPLIED_BONUSES_FROM_REVERTED_PURCHASE', {
                            count: event.params.amount,
                        })
                        : translate('Начисление бонусов с отмененной (или удаленной)'), "\u00A0", _jsx("span", Object.assign({ className: ((_z = event.params) === null || _z === void 0 ? void 0 : _z.purchaseId) ? styles.underline : '', onClick: () => { var _a; return handlePurchase((_a = event.params) === null || _a === void 0 ? void 0 : _a.purchaseId); } }, { children: event.params.amount
                            ? translate('purchase_range_one')
                            : translate('покупки') }))] })),
        };
    // Начислено Х бонусов с покупки друга
    if (event.eventType === '9') {
        const link = handleEntityLink('promocode', (_0 = event.params) === null || _0 === void 0 ? void 0 : _0.promocode);
        return {
            tooltipTitle: event.params.referralPurchaseClientName ? (_jsxs(_Fragment, { children: [translate('Друг'), ":\u00A0", _jsx("span", Object.assign({ className: event.params.referralPurchaseClientId
                            ? styles.tooltipFriendName
                            : '', onClick: event.params.referralPurchaseClientId
                            ? () => openOverview(event.params.referralPurchaseClientId)
                            : undefined }, { children: event.params.referralPurchaseClientName }))] })) : undefined,
            color: 'green',
            Icon: _jsx(BonusMovement, { className: styles.rotateIcon, fill: '#499E5C' }),
            title: (_jsxs(_Fragment, { children: [((_1 = event.params) === null || _1 === void 0 ? void 0 : _1.amount)
                        ? translate('BONUSES_FOR', { count: (_2 = event.params) === null || _2 === void 0 ? void 0 : _2.amount })
                        : translate('Бонусы за'), "\u00A0", _jsx("span", Object.assign({ className: ((_3 = event.params) === null || _3 === void 0 ? void 0 : _3.referralPurchaseId) ? styles.underline : '', onClick: () => { var _a; return handlePurchase((_a = event.params) === null || _a === void 0 ? void 0 : _a.referralPurchaseId); } }, { children: translate('покупку друга') })), "\u00A0", translate('по промокоду'), link && (_jsxs(_Fragment, { children: ["\u00A0\u00A0", _jsx(Link, Object.assign({ to: link, target: '_blank' }, { children: _jsx(Promocode, { code: (_4 = event.params) === null || _4 === void 0 ? void 0 : _4.promocode }) }))] }))] })),
        };
    }
    return {};
};
