import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { ChannelEmailAlt } from '~/src/icons/ChannelEmailAlt';
import { ChannelPushAlt } from '~/src/icons/ChannelPushAlt';
import { ChannelSMSAlt } from '~/src/icons/ChannelSMSAlt';
import { ChannelViberAlt } from '~/src/icons/ChannelViberAlt';
import { ClickHand } from '~/src/icons/ClickHand';
import { Send } from '~/src/icons/Send';
import styles from '../styles.module.scss';
export const mailingTitleRenderer = ({ event, translate, entityHandlers, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    const { handleEntityLink } = entityHandlers;
    // Клик по ссылке в письме
    if (event.eventType === 'sending_click') {
        const link = handleEntityLink('mailing', (_a = event.params) === null || _a === void 0 ? void 0 : _a.mailingBrandId, (_b = event.params) === null || _b === void 0 ? void 0 : _b.mailingBrandType);
        return {
            Icon: _jsx(ClickHand, {}),
            title: (_jsxs(_Fragment, { children: [translate('Кликнул по'), "\u00A0", event.params.linkHash ? (_jsx(Link, Object.assign({ className: styles.underline, to: event.params.linkHash, target: '_blank' }, { children: translate('ссылке') }))) : (_jsx("span", { children: translate('ссылке') })), "\u00A0", translate('в email'), "\u00A0", link ? (_jsx(Link, Object.assign({ className: styles.underline, to: link, target: '_blank' }, { children: event.params.mailingBrandName }))) : (((_c = event.params) === null || _c === void 0 ? void 0 : _c.mailingBrandName) && (_jsx("span", { children: `"${event.params.mailingBrandName}"` })))] })),
        };
    }
    // Запланирована рассылка
    if (event.eventType === 'mailing_event') {
        const link = handleEntityLink('mailing', (_d = event.params) === null || _d === void 0 ? void 0 : _d.mailingBrandId, (_e = event.params) === null || _e === void 0 ? void 0 : _e.mailingBrandType);
        return {
            Icon: _jsx(Send, { size: 20 }),
            title: (_jsxs(_Fragment, { children: [translate('Запланирована рассылка'), "\u00A0", link ? (_jsx(Link, Object.assign({ className: styles.underline, to: link, target: '_blank' }, { children: event.params.mailingBrandName }))) : (((_f = event.params) === null || _f === void 0 ? void 0 : _f.mailingBrandName) && (_jsx("span", { children: `"${event.params.mailingBrandName}"` })))] })),
        };
    }
    // Готовится к отправке
    if (event.eventType === 'mailing_sending') {
        const link = handleEntityLink('mailing', (_g = event.params) === null || _g === void 0 ? void 0 : _g.mailingBrandId, (_h = event.params) === null || _h === void 0 ? void 0 : _h.mailingBrandType);
        return {
            Icon: _jsx(Send, { size: 20 }),
            title: (_jsxs(_Fragment, { children: [translate('Готовится к отправке'), "\u00A0", link ? (_jsx(Link, Object.assign({ className: styles.underline, to: link, target: '_blank' }, { children: event.params.mailingBrandName }))) : (((_j = event.params) === null || _j === void 0 ? void 0 : _j.mailingBrandName) && (_jsx("span", { children: `"${event.params.mailingBrandName}"` })))] })),
        };
    }
    // Отправлено SMS
    if (event.eventType === 'sending_sms') {
        const link = handleEntityLink('mailing', (_k = event.params) === null || _k === void 0 ? void 0 : _k.mailingBrandId, (_l = event.params) === null || _l === void 0 ? void 0 : _l.mailingBrandType);
        return {
            Icon: _jsx(ChannelSMSAlt, {}),
            title: (_jsxs(_Fragment, { children: [translate('Отправлен SMS'), "\u00A0", link ? (_jsx(Link, Object.assign({ className: styles.underline, to: link, target: '_blank' }, { children: event.params.mailingBrandName }))) : (((_m = event.params) === null || _m === void 0 ? void 0 : _m.mailingBrandName) && (_jsx("span", { children: `"${event.params.mailingBrandName}"` })))] })),
        };
    }
    // Отправлен Viber
    if (event.eventType === 'sending_viber') {
        const link = handleEntityLink('mailing', (_o = event.params) === null || _o === void 0 ? void 0 : _o.mailingBrandId, (_p = event.params) === null || _p === void 0 ? void 0 : _p.mailingBrandType);
        return {
            Icon: _jsx(ChannelViberAlt, {}),
            title: (_jsxs(_Fragment, { children: [translate('Отправлен Viber'), "\u00A0", link ? (_jsx(Link, Object.assign({ className: styles.underline, to: link, target: '_blank' }, { children: event.params.mailingBrandName }))) : (((_q = event.params) === null || _q === void 0 ? void 0 : _q.mailingBrandName) && (_jsx("span", { children: `"${event.params.mailingBrandName}"` })))] })),
        };
    }
    // Отправлен email
    if (event.eventType === 'sending_email') {
        const link = handleEntityLink('mailing', (_r = event.params) === null || _r === void 0 ? void 0 : _r.mailingBrandId, (_s = event.params) === null || _s === void 0 ? void 0 : _s.mailingBrandType);
        return {
            Icon: _jsx(ChannelEmailAlt, {}),
            title: (_jsxs(_Fragment, { children: [translate('Отправлен email'), "\u00A0", link ? (_jsx(Link, Object.assign({ className: styles.underline, to: link, target: '_blank' }, { children: event.params.mailingBrandName }))) : (((_t = event.params) === null || _t === void 0 ? void 0 : _t.mailingBrandName) && (_jsx("span", { children: `"${event.params.mailingBrandName}"` })))] })),
        };
    }
    // Отправлен Push
    if (event.eventType === 'sending_push' ||
        event.eventType === 'sending_webpush') {
        const link = handleEntityLink('mailing', (_u = event.params) === null || _u === void 0 ? void 0 : _u.mailingBrandId, (_v = event.params) === null || _v === void 0 ? void 0 : _v.mailingBrandType);
        return {
            Icon: _jsx(ChannelPushAlt, {}),
            title: (_jsxs(_Fragment, { children: [translate('Отправлен Push'), "\u00A0", link ? (_jsx(Link, Object.assign({ className: styles.underline, to: link, target: '_blank' }, { children: event.params.mailingBrandName }))) : (((_w = event.params) === null || _w === void 0 ? void 0 : _w.mailingBrandName) && (_jsx("span", { children: `"${event.params.mailingBrandName}"` })))] })),
        };
    }
    return {};
};
