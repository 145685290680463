import { bonusTitleRenderer } from './bonus';
import { clientUpdateTitleRenderer } from './clientUpdate';
import { loyaltyTitleRenderer } from './loyalty';
import { mailingTitleRenderer } from './mailing';
import { purchaseTitleRenderer } from './purchase';
export const titleRenderer = ({ event, translate, entityHandlers, }) => {
    switch (event.eventGroup) {
        case 'bonus': {
            return bonusTitleRenderer({ event, translate, entityHandlers });
        }
        case 'loyalty': {
            return loyaltyTitleRenderer({ event, translate, entityHandlers });
        }
        case 'purchase': {
            return purchaseTitleRenderer({ event, translate, entityHandlers });
        }
        case 'mailing': {
            return mailingTitleRenderer({ event, translate, entityHandlers });
        }
        case 'client_update': {
            return clientUpdateTitleRenderer(event, translate);
        }
        default:
            return { title: '' };
    }
};
