import { ACCESS_OPTIONS } from '../constants';
export const defaultValuesFormater = (data) => {
    if (!data)
        return null;
    const permissionsMap = data.permissions.reduce((acc, item) => {
        acc[item] = true;
        return acc;
    }, {});
    const { 
    // Поля которые нужно вычислить
    CLIENTS_LIST, CLIENTS_LIST_OWN, CLIENTS_MANAGE, CLIENTS_MANAGE_OWN, OPERATORS_LIST, OPERATORS_MANAGE, OPERATORS_ADMIN_MANAGE, 
    // Поля которые добавляются руками
    CLIENTS_BONUSES_MANAGE, GIFTCARDS_INSTANCES_MANAGE, CLIENTS_PERSONAL_DATA_VIEW, DASHBOARD_VIEW, SMARTRFM_VIEW, EXPORTS_MANAGE, BILLING_VIEW, BILLING_INVOICES_MANAGE, REPORTS_MANAGE, REPORTS_VIEW, AUTO_MAILINGS_MANAGE, AUTO_MAILINGS_LIST, MANUAL_MAILINGS_MANAGE, MANUAL_MAILINGS_LIST, CLIENTS_PURCHASES_MANAGE, CLIENTS_PURCHASES_LIST, CLIENTS_SEGMENTS_MANAGE, CLIENTS_SEGMENTS_LIST, FLOWS_MANAGE, FLOWS_LIST, GIFTCARDS_MANAGE, GIFTCARDS_LIST, OFFERS_MANAGE, OFFERS_LIST, PROMOCODES_MANAGE, PROMOCODES_LIST, SHOPS_MANAGE, SHOPS_LIST, WALLET_CARDS_MANAGE, WALLET_CARDS_LIST, } = permissionsMap;
    const clientList = CLIENTS_LIST || CLIENTS_LIST_OWN
        ? ACCESS_OPTIONS.ACCESS
        : CLIENTS_MANAGE || CLIENTS_MANAGE_OWN
            ? ACCESS_OPTIONS.EDITING
            : ACCESS_OPTIONS.NO_ACCESS;
    const clientListOwn = clientList
        ? CLIENTS_LIST || CLIENTS_MANAGE
            ? ACCESS_OPTIONS.ALL_CLIENTS
            : ACCESS_OPTIONS.OWN_CLIENTS
        : undefined;
    const operatorsList = OPERATORS_LIST
        ? ACCESS_OPTIONS.ACCESS
        : OPERATORS_MANAGE || OPERATORS_ADMIN_MANAGE
            ? ACCESS_OPTIONS.EDITING
            : ACCESS_OPTIONS.NO_ACCESS;
    const operatorsListOwn = operatorsList &&
        (OPERATORS_ADMIN_MANAGE
            ? ACCESS_OPTIONS.ALL_USERS
            : ACCESS_OPTIONS.ALL_USERS_EXEPTS_ADMINS);
    const formValues = {
        // Название роли
        name: data.name,
        // Были ли изменены свитчеры
        hasSwitches: false,
        // ************* АНАЛИТИКА *************
        // Сводка
        DASHBOARD_VIEW: DASHBOARD_VIEW
            ? ACCESS_OPTIONS.ACCESS
            : ACCESS_OPTIONS.NO_ACCESS,
        // Smart RFM
        SMARTRFM_VIEW: SMARTRFM_VIEW
            ? ACCESS_OPTIONS.ACCESS
            : ACCESS_OPTIONS.NO_ACCESS,
        // Отчеты
        reports: REPORTS_MANAGE
            ? ACCESS_OPTIONS.EDITING
            : REPORTS_VIEW
                ? ACCESS_OPTIONS.ACCESS
                : ACCESS_OPTIONS.NO_ACCESS,
        // Выгрузка
        EXPORTS: EXPORTS_MANAGE ? ACCESS_OPTIONS.ACCESS : ACCESS_OPTIONS.NO_ACCESS,
        // ************* КЛИЕНТЫ *************
        // Список клиентов
        clientList,
        // Список клиентов (собственные или все)
        clientListOwn,
        // Начисление бонусов
        CLIENTS_BONUSES_MANAGE: !!CLIENTS_BONUSES_MANAGE,
        // Сегменты
        CLIENTS_SEGMENTS: CLIENTS_SEGMENTS_MANAGE
            ? ACCESS_OPTIONS.EDITING
            : CLIENTS_SEGMENTS_LIST
                ? ACCESS_OPTIONS.ACCESS
                : ACCESS_OPTIONS.NO_ACCESS,
        // Покупки
        CLIENTS_PURCHASES: CLIENTS_PURCHASES_MANAGE
            ? ACCESS_OPTIONS.EDITING
            : CLIENTS_PURCHASES_LIST
                ? ACCESS_OPTIONS.ACCESS
                : ACCESS_OPTIONS.NO_ACCESS,
        // Персон. данные
        CLIENTS_PERSONAL_DATA_VIEW: !!CLIENTS_PERSONAL_DATA_VIEW,
        // ************* ИНСТРУМЕНТЫ *************
        // Цепочки
        FLOWS: FLOWS_MANAGE
            ? ACCESS_OPTIONS.EDITING
            : FLOWS_LIST
                ? ACCESS_OPTIONS.ACCESS
                : ACCESS_OPTIONS.NO_ACCESS,
        // Ручн. рассылки
        MANUAL_MAILINGS: MANUAL_MAILINGS_MANAGE
            ? ACCESS_OPTIONS.EDITING
            : MANUAL_MAILINGS_LIST
                ? ACCESS_OPTIONS.ACCESS
                : ACCESS_OPTIONS.NO_ACCESS,
        // Авт. рассылки
        AUTO_MAILINGS: AUTO_MAILINGS_MANAGE
            ? ACCESS_OPTIONS.EDITING
            : AUTO_MAILINGS_LIST
                ? ACCESS_OPTIONS.ACCESS
                : ACCESS_OPTIONS.NO_ACCESS,
        // Карты Wallet
        WALLET_CARDS: WALLET_CARDS_MANAGE
            ? ACCESS_OPTIONS.EDITING
            : WALLET_CARDS_LIST
                ? ACCESS_OPTIONS.ACCESS
                : ACCESS_OPTIONS.NO_ACCESS,
        // Акции
        OFFERS: OFFERS_MANAGE
            ? ACCESS_OPTIONS.EDITING
            : OFFERS_LIST
                ? ACCESS_OPTIONS.ACCESS
                : ACCESS_OPTIONS.NO_ACCESS,
        // Промокоды и приведи друга
        PROMOCODES: PROMOCODES_MANAGE
            ? ACCESS_OPTIONS.EDITING
            : PROMOCODES_LIST
                ? ACCESS_OPTIONS.ACCESS
                : ACCESS_OPTIONS.NO_ACCESS,
        // Подарочные карты
        GIFTCARDS: GIFTCARDS_MANAGE
            ? ACCESS_OPTIONS.EDITING
            : GIFTCARDS_LIST
                ? ACCESS_OPTIONS.ACCESS
                : ACCESS_OPTIONS.NO_ACCESS,
        // Активация и блокировка
        GIFTCARDS_INSTANCES_MANAGE: !!GIFTCARDS_INSTANCES_MANAGE,
        // ************* БИЛЛИНГ *************
        // Детализация
        BILLING_VIEW: BILLING_VIEW
            ? ACCESS_OPTIONS.ACCESS
            : ACCESS_OPTIONS.NO_ACCESS,
        // Счета
        BILLING_INVOICES: BILLING_INVOICES_MANAGE
            ? ACCESS_OPTIONS.ACCESS
            : ACCESS_OPTIONS.NO_ACCESS,
        // ************* НАСТРОЙКИ *************
        // Пользователи
        operatorsList,
        // Пользователи (все или все кроме админов)
        operatorsListOwn,
        // Точки продажи
        SHOPS: SHOPS_MANAGE
            ? ACCESS_OPTIONS.EDITING
            : SHOPS_LIST
                ? ACCESS_OPTIONS.ACCESS
                : ACCESS_OPTIONS.NO_ACCESS,
    };
    return formValues;
};
