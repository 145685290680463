import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { Purchase } from '@/icons/Purchase';
import { GearsAlt } from '~/src/icons/GearsAlt';
import styles from '../styles.module.scss';
export const purchaseTitleRenderer = ({ event, translate, entityHandlers, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { handlePurchase, handleOrder, handleReturn, handleEntityLink } = entityHandlers;
    // Отредактировал покупку или заказ
    if (event.eventType === 'changed')
        return {
            Icon: _jsx(Purchase, {}),
            title: (_jsxs(_Fragment, { children: [translate('Измененены данные о'), "\u00A0", _jsx("span", Object.assign({ className: ((_a = event.params) === null || _a === void 0 ? void 0 : _a.purchaseId) ? styles.underline : '', onClick: () => { var _a; return handlePurchase((_a = event.params) === null || _a === void 0 ? void 0 : _a.purchaseId); } }, { children: translate('покупке') }))] })),
        };
    // Уменьшился счетчик
    if (event.eventType === 'expired') {
        const link = handleEntityLink('offer', (_b = event.params) === null || _b === void 0 ? void 0 : _b.offerId);
        return {
            color: 'gray',
            Icon: _jsx(GearsAlt, {}),
            title: (_jsxs(_Fragment, { children: [translate('EXPIRED_COUNTER', {
                        count: event.params.expiredCounter,
                    }), "\u00A0", link ? (_jsx(Link, Object.assign({ className: styles.underline, to: link, target: '_blank' }, { children: event.params.offerName }))) : (_jsx(_Fragment, { children: event.params.offerName ? (_jsx("span", { children: `"${event.params.offerName}"` })) : (_jsx("span", { children: translate('акции') })) })), "\u00A0", translate('сгорело')] })),
        };
    }
    // Сделал покупку
    if (event.eventType === 'purchase')
        return {
            Icon: _jsx(Purchase, {}),
            title: (_jsxs(_Fragment, { children: [translate('Сделал'), "\u00A0", _jsx("span", Object.assign({ className: ((_c = event.params) === null || _c === void 0 ? void 0 : _c.purchaseId) ? styles.underline : '', onClick: () => { var _a; return handlePurchase((_a = event.params) === null || _a === void 0 ? void 0 : _a.purchaseId); } }, { children: translate('покупку') }))] })),
        };
    // Сделал возврат
    if (event.eventType === 'purchase_return')
        return {
            color: 'red',
            Icon: _jsx(Purchase, {}),
            title: (_jsxs(_Fragment, { children: [translate('Сделал возврат в'), "\u00A0", _jsx("span", Object.assign({ className: ((_d = event.params) === null || _d === void 0 ? void 0 : _d.purchaseId) ? styles.underline : '', onClick: () => { var _a; return handleReturn((_a = event.params) === null || _a === void 0 ? void 0 : _a.purchaseId); } }, { children: translate('покупке') }))] })),
        };
    // Отмена (удаление покупки)
    if (event.eventType === 'purchase_revert')
        return {
            color: 'red',
            Icon: _jsx(Purchase, {}),
            title: (_jsxs(_Fragment, { children: [translate('Отменена (или удалена)'), "\u00A0", _jsx("span", Object.assign({ className: ((_e = event.params) === null || _e === void 0 ? void 0 : _e.purchaseId) ? styles.underline : '', onClick: () => { var _a; return handlePurchase((_a = event.params) === null || _a === void 0 ? void 0 : _a.purchaseId); } }, { children: translate('покупка') }))] })),
        };
    // Друг сделал покупку
    if (event.eventType === 'friend_purchase')
        return {
            Icon: _jsx(Purchase, {}),
            title: (_jsxs(_Fragment, { children: [translate('Друг совершил'), "\u00A0", _jsx("span", Object.assign({ className: ((_f = event.params) === null || _f === void 0 ? void 0 : _f.referralPurchaseId) ? styles.underline : '', onClick: () => { var _a; return handlePurchase((_a = event.params) === null || _a === void 0 ? void 0 : _a.referralPurchaseId); } }, { children: translate('покупку') }))] })),
        };
    // Сделал заказ
    if (event.eventType === 'order')
        return {
            Icon: _jsx(Purchase, {}),
            title: (_jsxs(_Fragment, { children: [translate('Сделал'), "\u00A0", _jsx("span", Object.assign({ className: event.refId ? styles.underline : '', onClick: () => handleOrder(event.refId) }, { children: ((_g = event.params) === null || _g === void 0 ? void 0 : _g.txid)
                            ? translate('ORDER_NUMBER', {
                                number: (_h = event.params) === null || _h === void 0 ? void 0 : _h.txid,
                            })
                            : translate('заказ') }))] })),
        };
    return {};
};
