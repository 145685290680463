import { ACCESS_OPTIONS } from '../constants';
export const roleDataSubmitFormater = (data, isCopyName = false, sections) => {
    const { name, clientList, clientListOwn, operatorsList, operatorsListOwn, reports, DASHBOARD_VIEW, SMARTRFM_VIEW, EXPORTS, CLIENTS_PERSONAL_DATA_VIEW, CLIENTS_BONUSES_MANAGE, BILLING_INVOICES, BILLING_VIEW, GIFTCARDS_INSTANCES_MANAGE, AUTO_MAILINGS, MANUAL_MAILINGS, CLIENTS_PURCHASES, CLIENTS_SEGMENTS, FLOWS, GIFTCARDS, OFFERS, PROMOCODES, SHOPS, WALLET_CARDS, } = data;
    // Ключи: "CLIENTS_LIST", "CLIENTS_LIST_OWN", "CLIENTS_MANAGE", "CLIENTS_MANAGE_OWN" вычисляются по двум полям - clientList и clientListOwn
    // Ключи: "OPERATORS_LIST", "OPERATORS_MANAGE", "OPERATORS_ADMIN_MANAGE", вычисляются по двум полям - operatorsList и operatorsListOwn
    // Список клиентов:
    // Нет доступа = не добавляем
    // Просмотр + Всех клиентов = CLIENTS_LIST
    // Просмотр + Только своих = CLIENTS_LIST_OWN
    // Редактирование + Всех клиентов = CLIENTS_MANAGE
    // Редактирование + Только своих = CLIENTS_MANAGE_OWN
    const clientsAccess = !clientList || clientList === ACCESS_OPTIONS.NO_ACCESS
        ? undefined
        : clientList === ACCESS_OPTIONS.ACCESS &&
            clientListOwn === ACCESS_OPTIONS.ALL_CLIENTS
            ? 'CLIENTS_LIST'
            : clientList === ACCESS_OPTIONS.ACCESS &&
                clientListOwn === ACCESS_OPTIONS.OWN_CLIENTS
                ? 'CLIENTS_LIST_OWN'
                : clientList === ACCESS_OPTIONS.EDITING &&
                    clientListOwn === ACCESS_OPTIONS.ALL_CLIENTS
                    ? 'CLIENTS_MANAGE'
                    : 'CLIENTS_MANAGE_OWN';
    // Пользователи:
    // Нет доступа = не добавляем
    // Просмотр + Всех кроме админов = OPERATORS_LIST
    // Редактирование + Всех кроме админов = OPERATORS_MANAGE
    // Просмотр ИЛИ Редактирование + Всех пользователей = OPERATORS_ADMIN_MANAGE
    const operatorsAccess = !operatorsList || operatorsList === ACCESS_OPTIONS.NO_ACCESS
        ? undefined
        : operatorsList === ACCESS_OPTIONS.ACCESS &&
            operatorsListOwn === ACCESS_OPTIONS.ALL_USERS_EXEPTS_ADMINS
            ? 'OPERATORS_LIST'
            : operatorsList === ACCESS_OPTIONS.EDITING &&
                operatorsListOwn === ACCESS_OPTIONS.ALL_USERS_EXEPTS_ADMINS
                ? 'OPERATORS_MANAGE'
                : (operatorsList === ACCESS_OPTIONS.EDITING ||
                    operatorsList === ACCESS_OPTIONS.ACCESS) &&
                    operatorsListOwn === ACCESS_OPTIONS.ALL_USERS &&
                    'OPERATORS_ADMIN_MANAGE';
    const permissions = [];
    // ************* АНАЛИТИКА *************
    if (sections.analytics) {
        if (DASHBOARD_VIEW === ACCESS_OPTIONS.ACCESS)
            // Сводка
            permissions.push('DASHBOARD_VIEW');
        // Smart RFM
        if (SMARTRFM_VIEW === ACCESS_OPTIONS.ACCESS)
            permissions.push('SMARTRFM_VIEW');
        // Отчеты
        if (reports === ACCESS_OPTIONS.EDITING)
            permissions.push('REPORTS_MANAGE');
        if (reports === ACCESS_OPTIONS.ACCESS)
            permissions.push('REPORTS_VIEW');
        // Выгрузка
        if (EXPORTS === ACCESS_OPTIONS.ACCESS)
            permissions.push('EXPORTS_MANAGE');
    }
    // ************* КЛИЕНТЫ *************
    if (sections.clients) {
        // Список клиентов + Список клиентов (собственные или все)
        if (clientsAccess)
            permissions.push(clientsAccess);
        // Начисление бонусов
        if (CLIENTS_BONUSES_MANAGE)
            permissions.push('CLIENTS_BONUSES_MANAGE');
        // Сегменты
        if (CLIENTS_SEGMENTS === ACCESS_OPTIONS.ACCESS)
            permissions.push('CLIENTS_SEGMENTS_LIST');
        if (CLIENTS_SEGMENTS === ACCESS_OPTIONS.EDITING)
            permissions.push('CLIENTS_SEGMENTS_MANAGE');
        // Покупки
        if (CLIENTS_PURCHASES === ACCESS_OPTIONS.ACCESS)
            permissions.push('CLIENTS_PURCHASES_LIST');
        if (CLIENTS_PURCHASES === ACCESS_OPTIONS.EDITING)
            permissions.push('CLIENTS_PURCHASES_MANAGE');
        // Персон. данные
        if (CLIENTS_PERSONAL_DATA_VIEW)
            permissions.push('CLIENTS_PERSONAL_DATA_VIEW');
    }
    // ************* ИНСТРУМЕНТЫ *************
    if (sections.tools) {
        if (FLOWS === ACCESS_OPTIONS.ACCESS)
            // Цепочки
            permissions.push('FLOWS_LIST');
        if (FLOWS === ACCESS_OPTIONS.EDITING)
            permissions.push('FLOWS_MANAGE');
        // Ручн. рассылки
        if (MANUAL_MAILINGS === ACCESS_OPTIONS.ACCESS)
            permissions.push('MANUAL_MAILINGS_LIST');
        if (MANUAL_MAILINGS === ACCESS_OPTIONS.EDITING)
            permissions.push('MANUAL_MAILINGS_MANAGE');
        // Авт. рассылки
        if (AUTO_MAILINGS === ACCESS_OPTIONS.ACCESS)
            permissions.push('AUTO_MAILINGS_LIST');
        if (AUTO_MAILINGS === ACCESS_OPTIONS.EDITING)
            permissions.push('AUTO_MAILINGS_MANAGE');
        // Карты Wallet
        if (WALLET_CARDS === ACCESS_OPTIONS.ACCESS)
            permissions.push('WALLET_CARDS_LIST');
        if (WALLET_CARDS === ACCESS_OPTIONS.EDITING)
            permissions.push('WALLET_CARDS_MANAGE');
        // Акции
        if (OFFERS === ACCESS_OPTIONS.ACCESS)
            permissions.push('OFFERS_LIST');
        if (OFFERS === ACCESS_OPTIONS.EDITING)
            permissions.push('OFFERS_MANAGE');
        // Промокоды и приведи друга
        if (PROMOCODES === ACCESS_OPTIONS.ACCESS)
            permissions.push('PROMOCODES_LIST');
        if (PROMOCODES === ACCESS_OPTIONS.EDITING)
            permissions.push('PROMOCODES_MANAGE');
        // Подарочные карты
        if (GIFTCARDS === ACCESS_OPTIONS.ACCESS)
            permissions.push('GIFTCARDS_LIST');
        if (GIFTCARDS === ACCESS_OPTIONS.EDITING)
            permissions.push('GIFTCARDS_MANAGE');
        // Активация и блокировка
        if (GIFTCARDS_INSTANCES_MANAGE)
            permissions.push('GIFTCARDS_INSTANCES_MANAGE');
    }
    // ************* БИЛЛИНГ *************
    if (sections.billing) {
        // Детализация
        if (BILLING_VIEW === ACCESS_OPTIONS.ACCESS)
            permissions.push('BILLING_VIEW');
        // Счета
        if (BILLING_INVOICES === ACCESS_OPTIONS.ACCESS)
            permissions.push('BILLING_INVOICES_MANAGE');
    }
    // ************* НАСТРОЙКИ *************
    if (sections.settings) {
        // Пользователи + Пользователи (все или все кроме админов)
        if (operatorsAccess)
            permissions.push(operatorsAccess);
        // Точки продажи
        if (SHOPS === ACCESS_OPTIONS.ACCESS)
            permissions.push('SHOPS_LIST');
        if (SHOPS === ACCESS_OPTIONS.EDITING)
            permissions.push('SHOPS_MANAGE');
    }
    return {
        name: isCopyName ? `Копия ${name}` : name,
        permissions,
    };
};
