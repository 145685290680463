import { jsx as _jsx } from "react/jsx-runtime";
import { Edit } from '~/src/icons/Edit';
import { User } from '~/src/icons/User';
export const clientUpdateTitleRenderer = (event, translate) => {
    // Создание
    if (event.eventType === '0')
        return {
            title: translate('Добавлен в ПЛ'),
            tooltipTitle: event.params.operatorName
                ? translate('CREATED_BY_EMPLOYEE', {
                    operatorName: event.params.operatorName,
                })
                : undefined,
            Icon: _jsx(User, { size: 20 }),
        };
    // Изменены данные
    if (event.eventType === '1')
        return {
            title: translate('Изменены данные'),
            tooltipTitle: event.params.operatorName
                ? translate('UPDATED_BY_EMPLOYEE', {
                    operatorName: event.params.operatorName,
                })
                : undefined,
            Icon: _jsx(Edit, { fill: '#3A3A3A' }),
        };
    // Изменены данные (клиентом)
    if (event.eventType === '2')
        return {
            title: translate('Изменил свои данные'),
            Icon: _jsx(Edit, { fill: '#3A3A3A' }),
        };
    // Удаление
    if (event.eventType === '3')
        return {
            color: 'red',
            title: translate('Удален'),
            Icon: _jsx(Edit, { fill: '#3A3A3A' }),
            tooltipTitle: event.params.operatorName
                ? translate('DELETED_BY_EMPLOYEE', {
                    operatorName: event.params.operatorName,
                })
                : undefined,
        };
    // Заблокирован
    if (event.eventType === '4')
        return {
            color: 'red',
            title: translate('Заблокирован'),
            Icon: _jsx(Edit, { fill: '#3A3A3A' }),
            tooltipTitle: event.params.operatorName
                ? translate('BLOCKED_BY_EMPLOYEE', {
                    operatorName: event.params.operatorName,
                })
                : undefined,
        };
    // Разблокирован
    if (event.eventType === '5')
        return {
            color: 'red',
            title: translate('Разблокирован'),
            Icon: _jsx(Edit, { fill: '#3A3A3A' }),
            tooltipTitle: event.params.operatorName
                ? translate('UNBLOCKED_BY_EMPLOYEE', {
                    operatorName: event.params.operatorName,
                })
                : undefined,
        };
    // Создание (клиентом)
    if (event.eventType === '6')
        return {
            title: translate('Добавился в ПЛ'),
            Icon: _jsx(User, { size: 20 }),
        };
    // Удаление (клиентом)
    if (event.eventType === '7')
        return {
            title: translate('Удален (клиентом)'),
            Icon: _jsx(Edit, { fill: '#3A3A3A' }),
        };
    return {};
};
