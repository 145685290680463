import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { Table } from '@/components/Table/Table';
import styles from './styles.module.scss';
import { titleRenderer } from './titleRenderers/titleRenderer';
export const EventsTable = memo(({ isLoading, eventsData, openOverview, handleOrder, handleReturn, handlePurchase, handleEntityLink, }) => {
    const { t } = useTranslation();
    const columns = useMemo(() => [
        {
            id: 'event_date',
            title: t('Дата события'),
        },
        {
            id: 'clients',
            title: t('Клиент'),
        },
        {
            id: 'event-icon',
        },
        {
            id: 'event',
            title: t('Событие'),
        },
    ], [t]);
    const rows = eventsData.flatMap(([date, item]) => item.map((event, index) => {
        const { title, color, tooltipTitle, Icon } = titleRenderer({
            event,
            translate: t,
            entityHandlers: {
                handlePurchase,
                handleEntityLink,
                handleReturn,
                handleOrder,
                openOverview,
            },
        }) || {};
        return {
            id: event.id,
            cells: [
                _jsxs(_Fragment, { children: [moment(date).format('DD.MM.YYYY'), ' ', moment(event.createdAt).format('HH:mm')] }),
                _jsx("button", Object.assign({ onClick: () => openOverview(event.clientId), className: styles.clientButton }, { children: _jsx("span", Object.assign({ className: styles.clientName }, { children: event.clientName })) }), 'client' + index),
                _jsx(_Fragment, { children: Icon }),
                _jsxs("div", Object.assign({ className: classNames(styles.eventTitle, {
                        [styles.negativeEvent]: color === 'red',
                        [styles.positiveEvent]: color === 'green',
                        [styles.neutralEvent]: color === 'gray',
                    }) }, { children: [title, tooltipTitle && (_jsx(HelpTooltip, { className: styles.tooltipIcon, tooltipClassName: styles.tooltip, title: tooltipTitle, size: 'medium', placement: 'top', hasLightIcon: true }, 'tooltip' + index))] }), index),
            ],
        };
    }));
    return (_jsx(_Fragment, { children: _jsx(Table, { className: styles.table, cellClassNames: {
                0: styles.eventsDateCell,
                1: styles.eventTitleCell,
                2: styles.eventIconCell,
            }, needAdaptive: false, isLoading: isLoading, rows: rows, columns: columns }) }));
});
